const en = {
    index: {
        install: 'install',
        name: "CFA-AI",
        webTitle: 'Please use your mobile phone to scan the QR code to download',
        webTitle2:'Please open in Safari browser',
        download: 'download',
        ratings: 'ratings',
        age: 'age',
        complaint: 'Complaint',
        newFunction: 'What\'s New',
        version: 'Version',
        introduction: 'Application Introduction',
        rates: 'Ratings and Reviews',
        outof: 'Out of 5',
        information: 'Information',
        seller: 'Seller',
        size: 'Size',
        compatibility: 'Compatibility',
        languages: 'Languages',
        ageRating: 'Age Rating',
        price: 'Price',
        free: 'Free',
        age18: '18+',
        langs: 'Chinese & English',
        ios: 'IOS 9.0+',
        appPrivacy: 'App Privacy',
        disclaim: 'Disclaimer: This website only provides developers with App download and installation hosting. The content and operation-related matters in the App are the responsibility of the App developer and have nothing to do with this website.',
        app: {
            info: 'Welcome to CFA-AI Global, one of the world’s leading  asset exchanges! Trade over 1100 currencies including Bitcoin(BTC), Ethereum(ETH), Dogecoin(DOGE), MX Token(MX), etc.. As a World Leading Crypto exchange, CFA-AI is known for being the first to launch quality projects and meme coins, CFA-AI Global provides professional and secure digital assets trading and management services trusted by over 7 million users worldwide.',
            newf: 'This release includes bug fixes and performance improvements.'
        },
        company: 'CFA-AI Pro',
        copyright: '© 2021 CFA-AI Global Ltd.',
        copyLink:  'Copy',
        copySuccess:'Copy Success'


    }


}


export default en;
